    $(function(){
       showSlogan(); 
    });

function showSlogan() {

            $(".img").click(function() {
                if($(this).children(".layerP").is(":visible")) {
                    $(this).children(".layerP").css("display", "none");
                } else {
                $(this).children(".layerP").css("display", "block");
            }
            });

}