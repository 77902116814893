$(function () {

    $("#burgermenu").click(function () {

        
        $("#navigation").fadeToggle();
//        $("#nav_inhalt").fadeToggle();
        //$(".nav_part").fadeToggle();
        $("#overlay").slideToggle();
        $("main").fadeToggle("invisible");
        $("body").toggleClass("overflowHidden");
        $("footer").fadeToggle();
        $("#menubutton").toggleClass("open");
    });

});