    $(function(){
       clickMore(); 
 
    });

function clickMore() {

            $(".morebutton").click(function() {
                $(this).siblings(".fullText").toggleClass("desc_hidden");
                if($(this).siblings(".fullText").hasClass("desc_hidden")) {
                    $(this).children(".more_button_text").text("Mehr...");
                    $(this).children(".more_button_img").css("transform", "rotateZ(0deg)");
                      $(this).siblings(".fullText").css("max-height", "200px");
                      
                } else {
                    $(this).children(".more_button_text").text("Weniger...");
                    $(this).children(".more_button_img").css("transform", "rotateZ(180deg)");
                      $(this).siblings(".fullText").css("max-height", "inherit");
                }
                
    
                
            });
            $(".more_partner").click(function() {
//                if($(this).siblings("p").hasClass("partner_hidden")) {
//                    alert("Test");
//                }
               
            });
}

function partner() {
    if($("#partner")[0]) {
        moreSvg = $("#buttonlink").html();

        $("#partner").children("#partners").children(".partner").each(function() {
            h = $(this).children("p").height();
            if(h > 132) {
                $(this).children("p").addClass("partner_hidden");
                $(this).append('<a class="more_partner"><img class="more_button_img" src="'+moreSvg+'"><p>Mehr...</p></a>');
            }
        });
           
            
    }
                $(".more_partner").click(function() {
                if($(this).siblings("p").hasClass("partner_hidden")) {
                    $(this).siblings("p").removeClass("partner_hidden");
                    $(this).html('<img class="more_button_img" src="'+moreSvg+'"><p>Weniger...</p>');
                    $(this).children("img").css("transform", "rotateZ(180deg)");
                } else {
                    $(this).siblings("p").addClass("partner_hidden");
                    $(this).html('<img class="more_button_img" src="'+moreSvg+'"><p>Mehr...</p>');
                    $(this).children("img").css("transform", "rotateZ(0deg)");
                }
               
            });
}