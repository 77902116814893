var rtime;
var timeout = false;
var delta = 200;
$(window).resize(function() {
//    $("#navigation").css("display", "none");
    $("#navigation").attr("style", "");
    rtime = new Date();
    if (timeout === false) {
        timeout = true;
        setTimeout(resizeend, delta);
    }
});


function resizeend() {
    
    if (new Date() - rtime < delta) {
        setTimeout(resizeend, delta);
    } else {
        timeout = false;
        if($("#menubutton").is(":visible")) {
            $("#navigation").css("display", "none");
            $("#menubutton").removeClass("open");
            $("#overlay").fadeOut();
            $("#content_limiter").removeClass("invisible");
            $("main").css("display", "block");
            $("footer").css("display", "block");
            $("body").removeClass("overflowHidden");
        } else {
            $("#navigation").css("display", "block");
            $("main").css("display", "block");
            $("footer").css("display", "block");
            $("#overlay").css("display", "none");
            $("body").removeClass("overflowHidden");
            
        }
    }               
}